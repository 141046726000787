<template>
    <div class="Home h-100 d-flex flex-column" id="main">
        <header class="flex-shrink-1">
            <NavComponent></NavComponent>
        </header>
        <div id="content" class="d-flex flex-column flex-shrink-1 align-items-center">
            <div id="logoImgs" class="d-flex justify-content-center">
                <a href="/">
                    <LogoImage pWidthLogo="25vw"
                           pMaxWidthLogo="360"
                           pWidthSubt="25vw"
                           pMaxWidthSubt="326" />
                </a>
            </div>


            <div id="content" class="container flex-shrink-1">
                <div id="info" class="d-flex flex-row justify-content-center">
                    <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                        <div class="boxContent">
                            <div class="container text-left">
                                <br />
                                <!-- eslint-disable -->
                                <h3 class="bold-title">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="currentColor" class="bi bi-archive-fill" viewBox="0 0 16 16">
                                        <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15h9.286zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zM.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8H.8z" />
                                    </svg> Histórico {{d}} {{ato.tipo}} nº {{ato.numero}}
                                </h3>
                                <br />
                                <div class="row" id="unseen2">
                                    <h1 class="mt-4"><label>Remissões Ativas</label></h1>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="col-2">Espécie Normativa</th>
                                                <th scope="col" class="col-2">Número</th>
                                                <th scope="col" class="col-2">Ação</th>
                                                <th scope="col" class="col-1">Data Ato</th>
                                                <th scope="col" class="col-1">Publicação</th>
                                                <th scope="col" class="col-4">Ementa</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <!-- eslint-disable -->
                                            <tr v-for="ra in ativas">
                                                <td>{{ra.tipoa}}</td>
                                                <td>{{ra.numeroa}}</td>
                                                <td>{{ra.acao}}</td>
                                                <td>{{moment(ra.dataAtoa).format('DD/MM/YYYY')}}</td>
                                                <td>{{moment(ra.dataPuba).format('DD/MM/YYYY')}}</td>
                                                <td>{{ra.ementaa}}</td>
                                                <!-- eslint-enable -->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row" id="unseen">
                                    <h1 class="mt-8"><label>Remissões Passivas</label></h1>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="col-2">Espécie Normativa</th>
                                                <th scope="col" class="col-2">Número</th>
                                                <th scope="col" class="col-2">Ação</th>
                                                <th scope="col" class="col-1">Data Ato</th>
                                                <th scope="col" class="col-1">Publicação</th>
                                                <th scope="col" class="col-4">Ementa</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <!-- eslint-disable -->
                                            <tr v-for="rp in passivas">
                                                <td>{{rp.tipop}}</td>
                                                <td>{{rp.numerop}}</td>
                                                <td>{{rp.acao}}</td>
                                                <td>{{moment(rp.dataAtop).format('DD/MM/YYYY')}}</td>
                                                <td>{{moment(rp.dataPubp).format('DD/MM/YYYY')}}</td>
                                                <td>{{rp.ementap}}</td>
                                                <!-- eslint-enable -->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- eslint-enable -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-grow-1 mb-4" />

        <Footer class="mt-4" />
    </div>
</template>

<script>
    /* eslint-disable */
    import NavComponent from '@/shared/components/NavComponent.vue';
    import LogoImage from '@/shared/components/LogoImage.vue';
    import Footer from '@/shared/components/Footer.vue';

    import { mdiArchiveEdit } from '@mdi/js';

    export default {
        name: 'Historico',
        title: 'LegisOn',
        data: () => {
            return ({
                icons: [mdiArchiveEdit],
                hist: "",
                ativas: "",
                passivas: "",
                ato: "",
                d: "da"
            })
        },
        components: {
            NavComponent,
            LogoImage,
            Footer
        },
        mounted() {
            this.hist = "Histórico teste bla nº 1";
            this.load();
        },
        methods: {
            load: function () {
                var idato = this.$route.query.id;
                this.$http.get("/Public/Historico?idato=" + idato).then(result => {
                    this.ativas = result.data.ativa;
                    this.passivas = result.data.passiva;
                    this.ato = result.data.ato;
                    this.d = result.data.d;
                }).catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
        }
    }
    /* eslint-enable */
</script>

<style>
    @import 'Historico.css';
</style>